<template>
    <UModal v-model="isOpen">
        <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
            <template #header>
                <h1 class="text-2xl font-fakt-medium">Reset your password</h1>
            </template>


            <div v-if="!sent">
                <p class="mb-3">Please enter your email.</p>
                <UInput placeholder="your@email.com" v-model="email" label="Email" type="email" />
                <p class="text-sm text-gray-500 mt-2">
                    Once you click "Send reset link", we'll send a password reset link to the email address you provided.</p>
            </div>
            <div v-else>
                <span class="font-fakt-blond">If we find an account associated with the provided email address, we'll send an email with a password reset link.</span>
            </div>


            <template #footer v-if="!sent">
                
                <UButton variant="solid" class="w-full bg-wobby-purple-500 hover:bg-wobby-purple-400 justify-center rounded-md" @click="submit" block :loading="loading">
                    Send reset link
                </UButton>

            </template>

            <template #footer v-else>
                <UButton variant="solid" block class="w-full justify-center rounded-md" @click="isOpen = false" >
                   Close
                </UButton>
            </template>
        </UCard>
    </UModal>
</template>

<script setup lang="ts">
const props = defineProps({
    modelValue: Boolean,
})

const { requestPasswordReset } = useIdentityService()

const emit = defineEmits(['update:modelValue'])

const isOpen = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

let email = ref('')
let loading = ref(false)
let sent = ref(false)

const submit = async () => {
    loading.value = true
    await requestPasswordReset(email.value)
    sent.value = true
    setTimeout(() => {
        loading.value = false
    }, 750)
}


</script>